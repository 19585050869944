<template>
  <div class="approval-report-wrapper flex">
    <d-search :searchData="searchData" @searchEvent="searchList(1)" @exportEvent="exportList" @resetEvent="reset">
      <time-search ref="timeSearch" :param.sync="searchData.params" :options="$map.getter('BPMtimeTags')"  :default-select="7"></time-search>
    </d-search>
    <div class="inline-flex contentBox">
      <d-table :tableData="tableData" :columnData="columnData" class="contentTable"></d-table>
    </div>
    <d-paging :pager="pager" @change="searchList"></d-paging>
  </div>
</template>

<script>
import TimeSearch from "@/pages/statement/approval-report/components/time-search";
import DSearch from "@/components/d-search";
import DTable from "@/components/d-table";
import DPaging from "@/components/d-paging";
import {Arrays} from "@/utils/Arrays";
import {onDownload} from "@/utils/utils";
import {searchPeopleCondition} from "@/pages/statement/approval-report/components/peopleMixin";
export default {
  name: "member-statistics",
  mixins:[searchPeopleCondition],
  components: {DPaging, DTable, DSearch, TimeSearch},
  data() {
    return {
      peopleList:[],
      searchData: {
        searchItem: [
          {label:'审批人',value:'dealUser',type:'linkageSelect',options:this.peopleList,filterable:true,optionProps:{
              label: "name",value: "value",children:"children",emitPath:false
            }
          },
        ],
        params: {
          dealUser: '',
          startTime: '',
          endTime: ''
        },
        searchBtn: {
          queryBtn: true,
          resetBtn: true,
          exportBtn: true
        }
      },
      tableData: [],
      columnData: [
        {label: '审批人', prop: 'userName'},
        {label: '审批部门', prop: 'deptName'},
        {label: '审批数量', prop: 'totalNum'},
        {label: '平均耗时', prop: 'avgTimeConsuming'},
        {label: '超时数量', prop: 'timeoutTotal'},
        {label: '超时率', prop: 'timeoutRate'}
      ],
      pager: {
        count: 0,
        page: 1,
        rows: 10,
      }
    }
  },
  mounted(){
    this.getPeople();
    this.searchList()
  },
  methods:{
    _getParams(){
      return {
        dealUser:this.searchData.params.dealUser,
        endDate:this.searchData.params.endTime,
        startDate:this.searchData.params.startTime,
        pageNum: this.pager.page,
        pageSize: this.pager.rows
      }
    },

    // 查询列表
    searchList(page){
      if(!this.$refs.timeSearch.validate()) return ;
      page && (this.pager.page = 1,this.pager.rows = 10)
      this.$api.mentapi.aprMemberList(this._getParams()).then(({data})=>{
        this.tableData = data.records;
        this.pager.count = data.total;
      })
    },


    getPeople(){
      this.$api.personnel.sysPeopleTree().then((res) => {
        this.peopleList = new Arrays().map([res.data], (item) => {
          item.children && !item.children.length && item.userId && delete item.children;
          item.value = (item.mobile || 'd-'+item.deptId)+'' ;
          item.name = item.name;
          return item;
        })
        this.searchData.searchItem.forEach(ele => {
          if (ele.value === 'dealUser') {
            ele.options = this.peopleList
          }
        })
      });
    },

    // 导出
    exportList(){
      this.$api.mentapi.aprMemberExport({...this._getParams(),pageSize:10000,pageNum:1}).then(({headers,data})=>{
        onDownload(headers,data)
      })
    },

    reset(){
      this.$refs.timeSearch.reset();
      this.searchList()
    }
  }
}
</script>

<style scoped>

</style>