import {Arrays} from "@/components/dynamicForm/utils/Arrays";

export const searchPeopleCondition = {
    data(){
        return {
            peopleList:[]
        }
    },
    mounted(){
        this.getPeople()
    },
    methods:{
        //  获取人员树
        getPeople(){
            this.$api.dynamicForm.componentUser().then((res) => {
                const iterator = new Arrays({ children:'children' });
                this.peopleList = iterator.map([res.data], (item) => {
                    if (item.children && !item.children.length && item.userId) {
                        delete item.children;
                    }
                    item.value = item.userId || 'd-'+item.deptId ;
                    return item;
                });
                this.searchData.searchItem.forEach(ele => {
                    if (ele.value === 'name') {
                        ele.options = this.peopleList
                    }
                })
            });
        },
    }
}